import React from "react";
import { Layout } from "components/common";
import "./styles.sass";

const BlogLayout = ({
  children,
  showLinksInFooter = false,
  showM6Banner = true,
  showReinsuranceInFooter = true,
  showIntercom = true,
}) => (
  <Layout
    showLinksInFooter={showLinksInFooter}
    showM6Banner={showM6Banner}
    showReinsuranceInFooter={showReinsuranceInFooter}
    showIntercom={showIntercom}
  >
    <div className="blog-root">{children}</div>
  </Layout>
);

export default BlogLayout;
