import React, { ElementType } from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  postSlug,
} from "templates/Blog/utils/slugs";
import "./styles.sass";
import T from "types/index";
import Heading from "components/common/utils/Heading";

type Props = {
  post: T.Blog.Post
  headingAs: ElementType
}

class PostCard extends React.Component<Props> {
  render() {
    const { post, headingAs = "h4" } = this.props;
    const postLink = postSlug(post);
    return (
      <Link to={postLink} className="blog-post-list-item-nc">
        <div className="blog-post-list-item-image ">
          <GatsbyImage
            image={
              post.featuredImage.node.localFile.childImageSharp.gatsbyImageData
            }
            className="img-fluid category-image"
            alt={post.title}
          />
        </div>
        <div className="blog-post-category-item-title blog-title truncate-text-3">
          <Heading as={headingAs} className="h4 text-left">{post.title}</Heading>
        </div>
        <div
          className="blog-post-list-item-excerpt blog-exerpt truncate-text-3"
          dangerouslySetInnerHTML={{ __html: post.excerpt }}
        />
      </Link>
    );
  }
}

export default PostCard;
