import React from "react";
import cx from "classnames";
import { ElementType, HTMLAttributes, FC } from "react";

interface HeadingProps extends HTMLAttributes<HTMLOrSVGElement> {
  as: ElementType;
}

const Heading = ({
  as: HeadingTag = "div",
  children,
  className,
  ...otherProps
}: HeadingProps) => {
  return <HeadingTag className={cx("charles-head", className)} {...otherProps}>{children}</HeadingTag>;
};

export default Heading;
