import { graphql, Link } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Segment from 'utils/segment'
import CharlesMeta from "components/common/meta/CharlesMeta";
import BlogLayout from "components/common/blog/BlogLayout";
import SimpleList from "templates/Blog/common/PostList/SimpleList";
import PostListCarousel from "templates/Blog/common/PostList/Carousel";
import { categorySlug } from "templates/Blog/utils/slugs";
import Breadcrumb, {
  categoryBreadcrumbs,
} from "templates/Blog/common/Breadcrumb";
import dataJson from "./data.json";
import PostThumbnail from "templates/Blog/common/PostThumbnail";
import config from 'utils/siteConfig';

import "./styles.sass";

class Category extends React.Component {

  render() {
    const { pageContext, data } = this.props;
    const { category, featuredPosts, recentPosts } = data;
    const { siteUrl } = config;
    const {
      previousPagePath,
      nextPagePath,
      pageNumber,
      numberOfPages,
      humanPageNumber,
    } = pageContext;

    const recentPostsNodes = recentPosts.nodes;
    const latestArticle = recentPostsNodes[0];

    const metaData = dataJson[category.slug];

    const metaTitle = metaData ? metaData.title : category.name;
    const metaDescription = metaData ? metaData.description : category.name;

    return (
      <BlogLayout>
        <CharlesMeta
          title={metaTitle}
          description={metaDescription}
          canonicalPathname={categorySlug(category)}
          segmentCategory={Segment.CATEGORIES.BLOG}
        />
        <Helmet defer={false}>
          {previousPagePath !== "" && <link rel="prev" href={`${siteUrl}${previousPagePath}/`} />}
          {nextPagePath !== "" && <link rel="next" href={`${siteUrl}${nextPagePath}/`} />}
        </Helmet>
        <div className="d-flex justify-content-center">
          <div className="category-container container">
            <Breadcrumb links={categoryBreadcrumbs(category)} />
            <h1 className="h1-blog-category">{category.name}</h1>
            {pageNumber == 0 && recentPosts.nodes.length > 0 && (
              <>
                <h2 className="h2-blog-category">Dernier article</h2>
                <div className="latest-article">
                  <PostThumbnail post={latestArticle} />
                </div>
                <PostListCarousel
                  title="Les plus lus :"
                  posts={featuredPosts.nodes}
                  className="mt-4 mb-4"
                />
              </>
            )}
            <h2 className="h2-blog-category">Articles</h2>
            <SimpleList posts={recentPosts.nodes} headingAs="h3" />
            {numberOfPages > 1 && (
              <>
              <Row className="blog-post-pages-link">
                <Col xs={6} md={4} className="text-left">
                  {previousPagePath && (
                    <div className="blog-post-pages-link-prev">
                      <Link to={`${previousPagePath}/`}>{"<<"} Précédent</Link>
                    </div>
                  )}
                </Col>
                <Col md={4} className="text-center d-none d-md-block">
                  <div className="blog-post-pages-link-pages">
                    Page {humanPageNumber} / {numberOfPages}
                  </div>
                </Col>
                <Col xs={6} md={4} className="text-right">
                  {nextPagePath && (
                    <div className="blog-post-pages-link-next">
                      <Link to={`${nextPagePath}/`}>Suivant {">>"}</Link>
                    </div>
                  )}
                </Col>
              </Row>
              </>
            )}

            <div
              className="category-description"
              dangerouslySetInnerHTML={{ __html: category.description }}
            />
          </div>
        </div>
      </BlogLayout>
    );
  }
}

export default Category;

export const pageQuery = graphql`
  query BlogCategoryById($id: String!, $skip: Int!, $limit: Int!) {
    category: wpCategory(id: { eq: $id }) {
      id
      fields {
        structuredDatas
      }
      description
      name
      parent {
        id
      }
      slug
      posts {
        nodes {
          ...BlogPostThumbnail
        }
      }
    }
    recentPosts: allWpPost(
      filter: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } }
      sort: { fields: date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        ...BlogPostThumbnail
      }
    }
    featuredPosts: allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { id: { eq: $id } } } }
        isSticky: { eq: true }
      }
    ) {
      nodes {
        ...BlogPostThumbnail
      }
    }
  }
`;
