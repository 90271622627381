import React, { ElementType } from "react";
import PostCard from "templates/Blog/common/PostList/common/PostCard";
import "templates/Blog/common/PostList/common/styles.sass";
import T from "src/types";

type Props = {
  posts: T.Blog.Post[]
  headingAs: ElementType
}

class SimpleList extends React.Component<Props> {
  render() {
    const { posts } = this.props;
    return (
      <div className="blog-post-list-container-no-carousel">
        {posts.map((post, index) => (
          <div key={`blog_post_list_item_${index}`}>
            <PostCard post={post} headingAs="h4" />
          </div>
        ))}
      </div>
    );
  }
}

export default SimpleList;
