import React from "react";
import { Link } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  postSlug,
  postParentCategory,
  categorySlug,
} from "templates/Blog/utils/slugs";
import "./styles.sass";
import Heading from "components/common/utils/Heading";

class PostThumbnail extends React.Component {
  render() {
    const { post } = this.props;
    const postLink = postSlug(post);
    const mainCategory = postParentCategory(post);
    return (
      <div className="blog-post-thumbnail row">
        <div className="col-12 col-md-4 blog-post-thumbnail-image">
          <GatsbyImage
            image={
              post.featuredImage.node.localFile.childImageSharp
                .gatsbyImageData
            }
            className="img-fluid img-thumbnail-blog"
            alt={post.title}
          />
        </div>

        <div className="col blog-post-thumbnail-description">
          {mainCategory && (
            <Link className="blog-post-thumbnail-category" to={categorySlug(mainCategory)}>{mainCategory.name}</Link>
          )}
          <Heading className="blog-post-thumbnail-title truncate-text-2">
            <Link className="unstyled-link category-link" to={postLink}>
              {post.title}
            </Link>
          </Heading>
          <div
            className="blog-post-thumbnail-excerpt"
            dangerouslySetInnerHTML={{ __html: post.excerpt }}
          />
        </div>
      </div>
    );
  }
}

export default PostThumbnail;
